import { FC } from 'react';

interface NotificationBadgeProps {
    count: number | undefined;
}

const NotificationBadge: FC<NotificationBadgeProps> = ({ count }) => {
    if (count) {
        const displayCount = count > 99 ? '99+' : count;
        return (
            <span className="absolute top-0 left-4 inline-flex items-center justify-center w-fit min-w-[0.75rem] p-[3px] h-[0.75rem] text-[0.5625rem] bg-red-500 text-white font-semibold font-mono rounded-full">
                {displayCount}
            </span>
        );
    } else return null;
};

export { NotificationBadge };
