import { useErrorContext } from '../store/errorContext';
import { AxiosError } from 'axios';

export const useHandleError = () => {
    const { setError } = useErrorContext();

    const handleError = (e: AxiosError) => {
        if (e.code === 'ERR_NETWORK') {
            setError('Maintenance in progress');
        } else if (e.response?.status) {
            if (e.response.status === 408 || e?.response.status === 504) {
                setError('Maintenance in progress');
            } else if (e.response.status >= 500) {
                setError('Server error');
            } else if (e.response.status >= 400) {
                setError('Wrong state, please reload page');
            }
        }
    };

    return {
        handleError,
    };
};
